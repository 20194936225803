@tailwind base;
@tailwind components;

@layer base {

  body {
    @apply bg-blue dark:bg-slate-900 transition-background overscroll-none;
    .country-indicator {
      @apply bg-white
    }
    .mobile-menu-bar {
      @apply bg-blue dark:!bg-slate-900 border-b-beige dark:border-b-slate-800;
      
    }
    .mobile-menu {
      @apply bg-blue  dark:!bg-slate-900;
    }
  }
  body:has(.yellow-body) {
    @apply bg-yellow;
    .country-indicator {
      @apply bg-black dark:bg-white
    }
    .mobile-menu-bar {
      @apply bg-yellow;
    }
    .mobile-menu {
      @apply bg-yellow;
    }
  }

  body:has(.blue-body) {
    @apply bg-blue;

    .mobile-menu-bar {
      @apply bg-blue;
    }
    .mobile-menu {
      @apply bg-blue;
    }
  }

  body:has(.red-body) {
    @apply bg-red;
    .country-indicator {
      @apply bg-black dark:bg-white
    }
    .mobile-menu-bar {
      @apply bg-red;
    }
    .mobile-menu {
      @apply bg-red;
    }
    
  }

  body:has(.green-body) {
    @apply bg-green;
    .country-indicator {
      @apply bg-black dark:bg-white
    }
    .mobile-menu-bar {
      @apply bg-green;
    }
    .mobile-menu {
      @apply bg-green;
    }
    
  }

  body:has(.brown-body) {
    @apply bg-warning;
    .country-indicator {
      @apply bg-black dark:bg-white
    }
    .mobile-menu-bar {
      @apply bg-warning;
    }
    .mobile-menu {
      @apply bg-warning;
    }
    
  }

  article a {
    @apply underline underline-offset-[0.2em];
  }
  p {
    @apply text-lg lg:text-xl;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  a,
  li,
  .wrap-text {
    @apply break-words overflow-ellipsis;
    word-break: break-word;
  }

  ul {
    @apply list-disc my-[1.2em] pl-[1.6em];
  }

  ul > li {
    @apply my-[0.6em] text-lg lg:text-xl;
  }
  abbr {
    @apply underline decoration-dotted underline-offset-[0.2em];
  }

  a[href],
  button,

  *[tabindex='0'] {
    @apply focus-visible:outline focus-visible:outline-8 focus-visible:outline-offset-4 focus-visible:outline-darkerblue focus-visible:brightness-95 dark:focus-visible:outline-white;
  }

  html {
    -webkit-tap-highlight-color: transparent;
    @apply scroll-pt-[8rem] lg:scroll-pt-0;
  }
   h1[tabindex='-1'] {
    @apply focus-visible:outline-none
   } 

  .pb-safe {
    padding-bottom: env(safe-area-inset-bottom);
  }

  iframe[src*='youtube'] {
    aspect-ratio: 16 / 9;
    width: 100%;
    border-radius: 8px;
  }
}

.icon-add {
  display: block;
}

.icon-remove {
  display: none;
}

details[open] .icon-add {
  display: none;
}

details[open] .icon-remove {
  display: block;
}

.more-contrast-border {
  @apply more-contrast:border-[1px] more-contrast:border-black/40 more-contrast:dark:border-white/40;
}

.text-link {
  @apply hyphens-auto text-darkblue more-contrast:text-black more-contrast:dark:text-white underline underline-offset-[0.15em] hover:brightness-95 focus-visible:brightness-95 dark:text-blue lg:text-xl;
}

.avatar-radio-group:has(input:focus-visible) .avatar-indicator {
  @apply dark:outline-white outline outline-8 outline-offset-4 outline-darkerblue;
}

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 400ms;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 400ms;
}

/**
    *  Case card css
    *
*/

:is(.dark .bg-beige, .dark .bg-lightblue) {
  --bg-polygon: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 595 134' fill='none'%3E%3Cpath d='M594.228 133.5L-2 133.5L-2.00001 61.5L594.229 0.5L594.228 133.5Z' fill='%230F172A' fill-opacity='0.18'/%3E%3C/svg%3E");
}

:is(.bg-beige) {
  --bg-polygon: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 595 134' fill='none'%3E%3Cpath d='M594.228 133.5L-2 133.5L-2.00001 61.5L594.229 0.5L594.228 133.5Z' fill='%23F0C868' fill-opacity='0.18'/%3E%3C/svg%3E");
}

:is(.bg-lightblue) {
  --bg-polygon: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 595 134' fill='none'%3E%3Cpath d='M594.228 133.5L-2 133.5L-2.00001 61.5L594.229 0.5L594.228 133.5Z' fill='pink' fill-opacity='0.18'/%3E%3C/svg%3E");
}

.link-card-graphic {
  @apply relative bg-no-repeat bg-contain;
  background-image: var(--bg-polygon);
  background-position: 0 calc(100% + 1rem);
}

/* Container query needed to make polygon background look good on startpage and when card is in grid in /services */
@container (min-width: 400px) {
  .link-card-graphic {
    background-position: 0 calc(100% + 3rem);
  }
}

p.preamble {
  text-wrap: balance;
}

.case-card {
  @apply rounded-2xl;
}

.case-card-graphic {
  @apply relative bg-bottom bg-no-repeat;
  background-image: var(--bg-polygon);
}
.case-card:has(a:focus-visible),
.link-card:has(a:focus-visible){
  @apply outline outline-8 outline-darkerblue dark:outline-white;
}

summary::-webkit-details-marker {
  display: none;
 }
 
 summary {
  list-style: none;
 }

/* Only remove default focus is :has is supported. */
@supports selector(:has(*)) {
  .case-card a:focus-visible,
  .link-card a:focus-visible {
    outline: 0;
  }
}

body:has(dialog[open]) {
  overflow: hidden;
}

/* Youtube embed */

.yt-lite {
  background-color: #000;
  position: relative;
  display: block;
  contain: content;
  background-position: center center;
  background-size: cover;
  cursor: pointer;
  border-radius: 8px;
}

.yt-lite:has(button:focus-visible) {
  @apply outline outline-8 outline-offset-4 outline-darkerblue brightness-95 dark:outline-white;
}

/* gradient */
.yt-lite::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  /* background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAADGCAYAAAAT+OqFAAAAdklEQVQoz42QQQ7AIAgEF/T/D+kbq/RWAlnQyyazA4aoAB4FsBSA/bFjuF1EOL7VbrIrBuusmrt4ZZORfb6ehbWdnRHEIiITaEUKa5EJqUakRSaEYBJSCY2dEstQY7AuxahwXFrvZmWl2rh4JZ07z9dLtesfNj5q0FU3A5ObbwAAAABJRU5ErkJggg==); */
  background-position: top;
  background-repeat: repeat-x;
  height: 60px;
  padding-bottom: 50px;
  width: 100%;
  transition: all 0.2s cubic-bezier(0, 0, 0.2, 1);
}

/* responsive iframe with a 16:9 aspect ratio
    thanks https://css-tricks.com/responsive-iframes/
*/
.yt-lite::after {
  content: '';
  display: block;
  padding-bottom: calc(100% / (16 / 9));
}
.yt-lite > iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

/* play button */
.yt-lite > .lty-playbtn {
  width: 70px;
  height: 46px;
  background-color: #212121;
  z-index: 1;
  opacity: 0.8;
  border-radius: 14%; /* TODO: Consider replacing this with YT's actual svg. Eh. */
  transition: all 0.2s cubic-bezier(0, 0, 0.2, 1);
}
.yt-lite:hover > .lty-playbtn,
.lty-playbtn:focus {
  background-color: #f00;
  opacity: 1;
}

.lty-playbtn:focus {
  outline: 0;
}

/* play button triangle */
.yt-lite > .lty-playbtn:before {
  content: '';
  border-style: solid;
  border-width: 11px 0 11px 19px;
  border-color: transparent transparent transparent #fff;
}

.yt-lite > .lty-playbtn,
.yt-lite > .lty-playbtn:before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}

/* Post-click styles */
.yt-lite.lyt-activated {
  cursor: unset;
}
.yt-lite.lyt-activated::before,
.yt-lite.lyt-activated > .lty-playbtn {
  opacity: 0;
  pointer-events: none;
}

@tailwind utilities;
